<template class="mr-3">
  <div class="card">
    <a-skeleton v-if="loading" :active="true" :paragraph="{ rows: 4 }" />

    <div v-else class="card-body">
      <div class="d-flex mt-2 my-2">
        <div class="text-danger">
          <a-icon type="tag" />
          Les classes supprimées
        </div>
        <div class="ml-2">
          <a-icon type="tag" />
          Les classes actives
        </div>
      </div>
      <a-input-search
        :placeholder="$t('scolarite.charcherParClasse')"
        @keyup="(e) => onSearch(e.target.value)"
      />
      <br />
      <a-directory-tree default-expand-all @select="onSelect">
        <!--for classrooms without level "undefined"-->
        <a-tree-node :key="1" title="Autre" v-if="classroomCache[undefined]">
          <a-icon slot="icon" type="apartment" />

          <a-tree-node
            v-for="classroom in classroomCache[undefined]"
            :key="classroom._id"
            :title="classroom.name"
            is-leaf
          >
            <a-icon slot="icon" type="book" />
          </a-tree-node>
        </a-tree-node>
        <!--for classrooms with level-->

        <template v-for="(level, index, key) in levelLabes[schoolType]">
          <a-tree-node
            :key="key"
            v-if="classroomCache[index] && classroomCache[index].length > 0"
            :title="level"
          >
            <a-icon slot="icon" type="apartment" />

            <a-tree-node
              v-for="classroom in classroomCache[index]"
              :key="classroom._id"
              :title="classroom.name"
              is-leaf
            >
              <a-icon
                slot="icon"
                type="book"
                v-if="classroom.status == 'active'"
              />
              <a-icon slot="icon" type="book" class="text-danger" v-else />
            </a-tree-node>
          </a-tree-node>
        </template>
      </a-directory-tree>
      <!--    <div class="card-body">
      <h5 style="color: blue">Classes</h5>
      <br />
      <div v-for="item in classes" :key="item._id">
        <router-link :to="'/inscriptionScolarite/' + item._id">
          {{ item.name }}
        </router-link>
        <br /><br />
      </div>
    </div>-->
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import apiClient from "@/services/axios";
import router from "@/router";
import { mapState } from "vuex";

export default {
  computed: mapState(["settings", "user"]),

  data() {
    return {
      schoolType: null,
      classes: [],
      levelLabes: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },

      classroomData: {},
      classroomCache: {},
      loading: true,
    };
  },
  created() {
    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;

    apiClient
      .post("/classrooms/filter", {
        query: {},
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            status: 1,
          },
        },
      })
      .then((res) => {
        this.classes = res.data;
        this.classroomData = _.mapValues(
          _.groupBy(this.classes, "level"),
          (clist) => clist.map((elem) => _.omit(elem, "level"))
        );
        this.classroomCache = { ...this.classroomData };
      })
      .catch((e) => this.$message.error(this.$t("error.aucClasse")))
      .finally(() => (this.loading = false));
  },

  methods: {
    onSelect(keys, event) {
      const key = keys[0];
      for (const item in this.classroomData) {
        this.classroomData[item].forEach((ac) => {
          if (key == ac._id) router.push(`/inscriptionScolarite/${key}`);
        });
      }
    },
    onSearch(v) {
      if (v) {
        for (let [key, value] of Object.entries(this.classroomData)) {
          this.classroomCache[key.toString()] = value.filter((elem) =>
            elem.name.toLowerCase().includes(v.toLowerCase())
          );
        }
      } else {
        this.classroomCache = { ...this.classroomData };
      }
    },
  },
};
</script>
<style scoped>
.card >>> .ant-tree {
  font-size: 16px;
}
.card >>> .ant-tree-title {
  font-weight: 500;
}
</style>
